import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  get_categorieall,
  get_ingredienti,
  get_ingredientebyid,
  reset_ingrediente,
  get_categorie_ingredienti,
  cambia_stato_ingrediente,
} from "../store/actions/ordini";
import Spinner from "../components/Spinner";
import "../style/Portate.css";
import TableIngredienti from "../components/TableIngredienti";
import { loading_login } from "../store/actions/login";
import { useState } from "react";

const Ingredienti = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.ordini.loading);
  const categorieall = useSelector((state) => state.ordini.categorieall);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);

  const count = useSelector((state) => state.ordini.count);
  const totalPage = useSelector((state) => state.ordini.total_page);
  const limit = useSelector((state) => state.ordini.limit);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);



  useEffect(() => {
    async function fetchportate() {
      await dispatch(loading_login(true));
      await dispatch(get_ingredienti(offset, limit));
      await dispatch(get_categorieall());
      await dispatch(loading_login(false));
    }
    fetchportate();
  }, [currentPage]);

  const modifica = async (id) => {
    await dispatch(get_categorie_ingredienti(id));
    if (id != 0) {
      await dispatch(get_ingredientebyid(id));

      navigate(`../ingrediente/${id}`);
    } else {
      await dispatch(reset_ingrediente());
      navigate(`../ingrediente/0`);
    }
  };
  const toggle = async (id, stato) => {
    if (stato == 0) {
      await dispatch(cambia_stato_ingrediente(id, 1));
    } else {
      await dispatch(cambia_stato_ingrediente(id, 0));
    }
    await dispatch(loading_login(true));
    await dispatch(get_ingredienti(offset, limit));
    await dispatch(loading_login(false));
  };
  let itemList = [];
  const inserisciPagine = () => {
    for (let i = 1; i <= totalPage; i++) {
      if (i == currentPage) {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn"
          >
            {i}
          </a>
        );
      } else {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn purple"
          >
            {i}
          </a>
        );
      }
    }
    return itemList;
  };
  return (
    <div className="container-ordini">
      {id_ruolo == 1 ? (
        <div className="apritavolo">
          <a
            href="#fakeLink"
            onClick={() => modifica(0)}
            className="btn purple"
          >
            Nuovo Ingrediente
          </a>
        </div>
      ) : null}

      {loading || loading2 ? (
        <Spinner />
      ) : ingredienti != 0 && totalPage != -1 ? (
        <>
          <div className="contenuto-ordini">
            <TableIngredienti
              ingredienti={ingredienti}
              modifica={modifica}
              toggle={toggle}
              /* totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setOffset={setOffset}
              limit={limit} */
            />
          </div>
          <div className="nume-page">
            {totalPage > 0 ? inserisciPagine() : null}
          </div>
        </>
      ) : (
        "Non hai ancora caricato nessun ingrediente"
      )}
    </div>
  );
};

export default Ingredienti;
