import React from "react";
import "../style/Table.css";

const TableStorico = ({storico}) => {

  return (
    <div className="container-table">
      <section>
        <header className="header">
          <div className="col int">Quantità</div>
          <div className="col int">Nome</div>
          <div className="col int">Descrizione</div>
        </header>

        {storico[0].carrellos.map((item, index) => {
          let color = index % 2 > 0 ? "white" : "aliceblue";
          return (
            <div className="row" style={{ backgroundColor:color}} key={index}>
              <div className="col">{item.quantita}</div>
              <div className="col">
                {item.prodotti.categorie.nome} {item.prodotti.nome}
              </div>
              <div className="col">{item.descrizione}</div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default TableStorico;
