import React from "react";
import "../style/Table.css";

const TablePortate = ({ portate, modifica, toggle }) => {
  console.log("portate", portate);

  return (
    <div className="table">
      <section>
        <header className="header">
          <div className="col int">Categorie</div>
          <div className="col int">Prodotto</div>
          <div className="col int">Ingredienti</div>
          <div className="col int">Prezzo(€)</div>
          <div className="col int">offerta(€)</div>
          <div className="col int">Action</div>
        </header>

        {portate.map((portata, index) => (
          <div className="row" key={index}>
            <div className="col">{portata.categorie.nome}</div>
            <div className="col">{portata.nome}</div>
            <div className="col">{portata.ingredienti_it}</div>
            <div className="col">{portata.prezzo}</div>
            <div className="col">{portata.prezzo_offerte}</div>
            <div className="col">
              <a
                href="#fakeLink"
                onClick={() => modifica(portata.id_prodotto)}
                className="btn purple"
              >
                Modifica
              </a>
              <a
                href="#fakeLink"
                onClick={() => toggle(portata.id_prodotto, portata.stato)}
                className="btn purple"
              >
                {portata.stato == 0 ? "Attiva" : "Disattiva"}
              </a>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default TablePortate;
