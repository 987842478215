import React from "react";
import "../style/Personalizzami.css";

const Personalizzami = (props) => {
  return (
    <div className="card-personalizzami">
      <div className="titolo-personalizzami">{props.card.nome}</div>

      <img
        className="imagee-personalizzami"
        src={props.card.path}
        alt="BigCo Inc. logo"
      />

      <div className="conenitore-info-personalizzami">
        <hr></hr>
        <div className="pulsanti-personalizzami">
          <a
            href="#fakeLink"
            onClick={() =>
              props.prepara(props.card.id_prodotto, props.id_prodotto_interno)
            }
            className="btn purple"
          >
            Iniziamo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Personalizzami;
