import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import version from "../../package.json";
import Form from "../components/Form";
import { logini, getmenui } from "../store/actions/login";
import "../style/Login.css";

function Login({ isLogin }) {
  const dispatch = useDispatch();
  const id_persona = useSelector((state) => state.login.id_persona);
  const appcode = useSelector((state) => state.login.appcode);


  
  const loginAcque = async (email, password) => {
    const flag = await dispatch(logini(email, password));
    if (flag) return true;
   
  
  };

  const getMenu = async (value) => {
    await dispatch(getmenui(id_persona, appcode, value));
    
  };

  return (
    <div className="login">
      {isLogin  ? (
        <Navigate to="/" />
      ) : 
        <Form login={loginAcque} getMenu={getMenu} />
      }
      
      Versione {version.version}
    </div>
  );
}

export default Login;
