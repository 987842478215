import React from "react";
import "../style/Table.css";

const TableMenu = ({
  modifica,
  menu,
  toggle,
 /*  totalPage,
  currentPage,
  setCurrentPage,
  setOffset,
  limit, */
}) => {
 /*  let itemList = [];
  const inserisciPagine = () => {
    for (let i = 1; i <= totalPage; i++) {
      if (i == currentPage) {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn"
          >
            {i}
          </a>
        );
      } else {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn purple"
          >
            {i}
          </a>
        );
      }
    }
    return itemList;
  }; */
  return (
    <div className="table">
      <section>
        <header className="header">
          <div className="col int">Nome</div>
          <div className="col int">Piatti</div>
          <div className="col int">Prezzo (€)</div>
          <div className="col int">Action </div>
        </header>

        {menu[0].prodottis.map((smenu, index) => {
          let piatto = "";
          smenu.composizione_menus.map((prodotti, index) => {
            piatto =
              piatto +
              " " +
              prodotti.Prodotti_Correlati.categorie.nome +
              ": " +
              prodotti.Prodotti_Correlati.nome +
              ", ";
          });
          return (
            <div className="row" key={index}>
              <div className="col">{smenu.nome}</div>
              <div className="col">{piatto}</div>

              <div className="col">{smenu.prezzo} </div>

              <div className="col">
                <a
                  href="#fakeLink"
                  onClick={() => modifica(smenu.id_prodotto)}
                  className="btn purple"
                >
                  Modifica
                </a>
                <a
                  href="#fakeLink"
                  onClick={() => toggle(smenu.id_prodotto, smenu.stato)}
                  className="btn purple"
                >
                  {smenu.stato == 0 ? "Attiva" : "Disattiva"}
                </a>
              </div>
            </div>
          );
        })}
      </section>
     {/*  <div className="nume-page"> */}
        {/* <div className="pulsanti">Prev</div> */}
               {/*  {totalPage > 0 ? inserisciPagine() : null} */}
        
        {/* { itemList.map((item, index) => {
           console.log("ciao",item)
        })} */}
       {/*  <div className="pulsanti">Next</div> */}
     {/*  </div> */}
    </div>
  );
};

export default TableMenu;
