import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../style/Table.css";

const TableOrdiniAperti = ({
  ordini,
  chiudiordine,
  ordina,
  ruolo,
  storico,
}) => {
  let date_format;
  return (
    <div className="table">
      <section>
        <header className="header">
          <div className="col int">N° Ordine</div>
          <div className="col int">Data</div>
          <div className="col int">Tavolo</div>
          <div className="col int">N° Persone</div>
          <div className="col int">Totale(€)</div>
          <div className="col int">Stato</div>
          <div className="col int">Action</div>
        </header>

        {ordini.map((ordine, index) => {
          date_format = new Date(ordine.createdAt);
          return (
            <div className="row" key={index}>
              <div className="col">{ordine.id_ordine}</div>
              <div className="col">{date_format.toLocaleString()}</div>

              <div className="col">
                {ordine.ordini_tavolos[0].numero_tavolo}
              </div>
              <div className="col">{ordine.ordini_tavolos[0].nume_persone}</div>
              <div className="col">{ordine.totale} €</div>
              <div className="col">
                {ordine.stato === 0 ? "aperto" : "chiuso"}
              </div>
              <div className="col">
                {ruolo == 1 || ordine.stato == 1 ? null : (
                  <a
                    href="#fakeLink"
                    onClick={() =>
                      ordina(
                        ordine.id_ordine,
                        ordine.ordini_tavolos[0].nume_persone,
                        ordine.totale,
                        ordine.ordini_tavolos[0].numero_tavolo
                      )
                    }
                    className="btn purple"
                  >
                    Ordina
                  </a>
                )}
                {ordine.stato == 1 ? null : (
                  <a
                    href="#fakeLink"
                    onClick={() => chiudiordine(ordine.id_ordine)}
                    className="btn purple"
                  >
                    Chiudi
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default TableOrdiniAperti;
