import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { loading_login } from "../store/actions/login";
import { aggiungi_incrementa_prodotto_cart } from "../store/actions/ordini";
import "../style/Stepper.css";
import { setCarrello } from "../utils/session";
import uuid from "react-uuid";
import HamburgerInfo from "../components/HamburgerInfo";
import BunsInfo from "../components/BunsInfo";
import CotturaInfo from "../components/CotturaInfo";
import SpecialiInfo from "../components/SpecialiInfo";
import SalseInfo from "../components/SalseInfo";
import FormaggiInfo from "../components/FormaggiInfo";
import VerdureInfo from "../components/VerdureInfo";
import SalumiInfo from "../components/SalumiInfo";
import CarneInfo from "../components/CarneInfo";
import PiadinaInfo from "../components/PiadinaInfo";

const PiadinaStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const { id, uui } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const loading = useSelector((state) => state.ordini.loading);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const [page, setPage] = useState(0);
  let base_piadina = [];
  let basePiadina = [];
  ingredienti.forEach((element) => {
    if (
      element.categorie_ingredienti.nome == "Base per Piadina Personalizzata"
    ) {
      basePiadina .push(element);
      base_piadina.push(false);
    }
  });

  const [formData, setFormData] = useState({
    nome: "",
    nota: "",
    base_piadina: base_piadina,
    piadina: "",
    carne: [],
    salse: [],
    formaggi: [],
    verdure: [],
    salumi: [],
    special: [],
    ingredienti: [],
    totale: 0.0,
  });

  const FormTitles = [
    "Scegli la Base",
    "Scegli la Carne",
    "Scegli le Formaggi",
    "Scegli le Salumi",
    "Scegli le Verdure",
    "Scegli  Aggiunte Speciali",
  ];
  const handleIncrement = () => {
    let id_prodotto_interno = uuid();

    let descrizione = "La Piadina di " + formData.nome + ".";
    descrizione = descrizione + "  -CARNE: ";
    formData.carne.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -SALSE: ";
    formData.salse.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -FORMAGGI: ";
    formData.formaggi.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -SALUMI: ";
    formData.salumi.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -VERDURE: ";
    formData.verdure.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -SPECIAL: ";
    formData.special.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -NOTE: " + formData.nota;
    let prodotto = prodotti.find((pr) => pr.id_prodotto == id);
    if (!prodotto) {
      //TODO va gestita l'eccexione
      return;
    }
    const p = {
      id_prodotto: prodotto.id_prodotto,
      nome: "Piadina Personalizzato",
      ingredienti_it: descrizione,
      ingredienti_en: "",
     
      prezzo: formData.totale,
      quantita: 1,
      special: 1,
      newspecial: true,
      id_prodotto_interno: id_prodotto_interno,
    };

    dispatch(aggiungi_incrementa_prodotto_cart(p));
    dispatch(loading_login(false));

    navigate("/carrello");
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
          <PiadinaInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
            basePiadina={basePiadina}
            
          />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <CarneInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <SalseInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    } else if (page === 3) {
      return (
        <>
          <FormaggiInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    } else if (page === 4) {
      return (
        <>
          <SalumiInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    } else if (page === 5) {
      return (
        <>
          <VerdureInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    } else {
      return (
        <>
          <SpecialiInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
          />
        </>
      );
    }
  };
  return (
    <div className="container-stepper">
      <div className="progressbar">
        <div
          style={{
            width:
              page === 0
                ? "14.30%"
                : page == 1
                ? "28.5%"
                : page == 2
                ? "42.8%"
                : page == 3
                ? "56,9%"
                : page == 4
                ? "71,3%"
                : page == 5
                ? "85.6%"
                : "100%",
          }}
        ></div>
      </div>
      <div className="titolo-page">{FormTitles[page]} </div>
      {loading ? <Spinner /> : <div className="">{PageDisplay()}</div>}
      <div className="ingredienti">{error != "" ? error : null}</div>

      <div className="pizza-btn-container">
        {page > 0 ? (
          <a
            href="#fakeLink"
            disabled={page == 0}
            onClick={() => {
              setPage((currPage) => currPage - 1);
              setError("");
            }}
            className="btn purple"
          >
            Indietro
          </a>
        ) : null}
        <a
          href="#fakeLink"
          onClick={() => {
            if (page === FormTitles.length - 1) {
              if (
                formData.nome != "" /*  || */ &&
                formData.pane != "" &&
                formData.carne != "" &&
                formData.cottura != ""
              ) {
                handleIncrement();
                setError("");
              } else {
                setError(
                  "Il nome, il pane, la carne e la cottura sono obbligatori"
                );
              }
            } else {
              setPage((currPage) => currPage + 1);
              setError("");
            }
          }}
          className="btn purple"
        >
          {page === FormTitles.length - 1 ? "Conferma" : "Avanti"}
        </a>
      </div>
      <div className="totale-pizza">
        {" "}
        Per la tua piadina stai spendendo:{" "}
        {Number(formData.totale).toFixed(2)} Euro
      </div>
    </div>
  );
};

export default PiadinaStepper;
