import React from "react";
import "../style/AntipastiInfo.css";

const PrezzoStatoInfo = (props) => {

  return (
    <div className="conteiner-antipasti">
        <div className="field field_v1">
          <input
            id="first-name"
            type="text"
            className="field__input"
            placeholder="Scrivi il tuo nome"
            required
            onChange={(e) =>
              props.setFormData({ ...props.formData, nome: e.target.value })
            }
            value={props.formData.nome}
          />
          <span className="field__label-wrap" aria-hidden="true">
            <span className="field__label">Nome</span>
          </span>
        </div>
        <div className="field field_v2">
            <input
              id="last-name"
              type="number"
              className="field__input"
              placeholder="Es. 4.5"
              onChange={(e) =>
                props.setFormData({ ...props.formData, prezzo: e.target.value })
              }
              value={props.formData.prezzo}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Prezzo (€)</span>
            </span>
          </div>
          <div className="field field_v2">
            <input
              id="last-name"
              type="file"
              className="field__input"
              placeholder="Inserisci il numero dei commensali"
              onChange={props.fileChangedHandler}
            />
          </div>
    </div>
  );
};
export default PrezzoStatoInfo;
