import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SelectCategorie from "../components/SelectCategorie";
import SelectStato from "../components/SelectStato";
import { loading_login } from "../store/actions/login";
import Spinner from "../components/Spinner";
import Resizer from "react-image-file-resizer";

import {
  salva_prodotto,
  modifica_prodotto,
} from "../store/actions/ordini";
import "../style/Piatti.css";

const Piatti = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categorieall = useSelector((state) => state.ordini.categorieall);
  const loading2 = useSelector((state) => state.login.loading);
  const prodotto = useSelector((state) => state.ordini.prodotto);
  const { id } = useParams();
  let [formData, setFormData] = useState(
    prodotto.length != 0
      ? {
          id_prodotto: prodotto[0].prodottis[0].id_prodotto,
          nome: prodotto[0].prodottis[0].nome,
          ingredienti_it: prodotto[0].prodottis[0].ingredienti_it,
          ingredienti_en: prodotto[0].prodottis[0].ingredienti_en,
          image: "",
          id_categoria: prodotto[0].id_categoria,
          prezzo: prodotto[0].prodottis[0].prezzo,
          prezzo_offerte: prodotto[0].prodottis[0].prezzo_offerte,
          stato: prodotto[0].prodottis[0].stato,
        }
      : {
          id_prodotto: "",
          nome: "",
          ingredienti_it: "",
          ingredienti_en: "",
          image: "",
          id_categoria: "",
          prezzo: "",
          prezzo_offerte: 0,
          stato: "",
        }
  );


  const stato = [
    { id: 1, nome: "attivo", valore: 1 },
    { id: 2, nome: "disattivo", valore: 2 },
  ];

  const salvaProdotto = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(salva_prodotto(formData))) navigate("/portate");
    await dispatch(loading_login(false));
  };
  const modificaProdotto = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(modifica_prodotto(formData))) navigate("/portate");
    await dispatch(loading_login(false));
  };
  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
       
           
            setFormData({ ...formData, image: uri })
            console.log(uri)
            
          },
          
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }
  
  return (
    <div className="page">
      {formData.length == 0 || formData == null || loading2 ? (
        <Spinner />
      ) : (
        <>
          <div className="field field_v1">
            <input
              id="first-name"
              type="text"
              className="field__input"
              placeholder="Inserisci il numero del tavolo"
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
              value={formData.nome}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Nome</span>
            </span>
          </div>
          <div className="field field_v1">
            <textarea
              id="first-name"
              type="text"
              className="field__input"
              placeholder="Inserisci una nota"
              onChange={(e) =>
                setFormData({ ...formData, ingredienti_it: e.target.value })
              }
              value={formData.ingredienti_it}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Ingredienti (ita)</span>
            </span>
          </div>
          <div className="field field_v1">
            <textarea
              id="first-name"
              type="text"
              className="field__input"
              placeholder="Inserisci una nota"
              onChange={(e) =>
                setFormData({ ...formData, ingredienti_en: e.target.value })
              }
              value={formData.ingredienti_en}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Ingredienti (en)</span>
            </span>
          </div>
          <div className="field field_v2">
            <input
              id="last-name"
              type="file"
              className="field__input"
              placeholder="Inserisci il numero dei commensali"
              onChange={fileChangedHandler}
            />
          </div>
          <div className="container-ruoli">
            <SelectCategorie
              formData={formData}
              setFormData={setFormData}
              categorieall={categorieall}
            />
          </div>
          <div className="container-ruoli">
            <SelectStato
              formData={formData}
              setFormData={setFormData}
              stato={stato}
            />
          </div>
          <div className="field field_v2">
            <input
              id="last-name"
              type="number"
              className="field__input"
              placeholder="Es. 4.5"
              onChange={(e) =>
                setFormData({ ...formData, prezzo: e.target.value })
              }
              value={formData.prezzo}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Prezzo (€)</span>
            </span>
          </div>
          <div className="field field_v2">
            <input
              id="last-name"
              type="number"
              className="field__input"
              placeholder="Es. 2.5"
              onChange={(e) =>
                setFormData({ ...formData, prezzo_offerte: e.target.value })
              }
              value={formData.prezzo_offerte}
            />
            <span className="field__label-wrap" aria-hidden="true">
              <span className="field__label">Prezzo Offerta (€)</span>
            </span>
          </div>
          {id == 0 ? (
            <a
              href="#fakeLink"
              onClick={() => salvaProdotto(formData)}
              className="btn purple"
            >
              Inserisci
            </a>
          ) : (
            <a
              href="#fakeLink"
              onClick={() => modificaProdotto(formData)}
              className="btn purple"
            >
              Modifica
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default Piatti;
