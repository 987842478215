import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import "../style/Layout.css";

import Navbar from "./Navbar";

const Layout = (props) => {
  const loading = useSelector((state) => state.login.loading);

  return (
    <div className="container-layout">
      
      <div className="subcontainer-layout">
        {props.isLogin ? (
          <>
            <Navbar />
          </>
        ) : null}
        <div className="corpo">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Layout;
