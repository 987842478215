import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "../style/Topbar.css";

const Topbar = (props) => {
  const qty_prodotti = useSelector((state) => state.ordini.qty_prodotti);
  const tot_prezzo = useSelector((state) => state.ordini.tot_prezzo);
  const coperti = useSelector((state) => state.ordini.coperti);
  const tot_generale = useSelector((state) => state.ordini.tot_generale);
  const tavolo = useSelector((state) => state.ordini.tavolo);

  return (
    <div className="container-topbar">
      <div className="gruppo_icone">
        <div className="menu-topbar">
          <span className="material-symbols-outlined">restaurant</span>
          <label>{coperti}</label>
        </div>
        <div className="menu-topbar">-</div>
        {!tot_generale == 0.0 ? (
          <>
            <div className="menu-topbar">
              <span className="material-symbols-outlined">payments</span>
              <label>{tot_generale.toFixed(2)} €</label>
            </div>
            <div className="menu-topbar">-</div>
          </>
        ) : null}
        <div className="menu-topbar">
          <span className="material-symbols-outlined">shopping_bag</span>
          <label>{tot_prezzo.toFixed(2)} €</label>
        </div>
        <div className="menu-topbar">
          <span className="material-symbols-outlined">table_restaurant</span>
          <label> {tavolo} </label>
        </div>
      </div>
      <div className="gruppo_icone">
        
          <>
            <NavLink
              to="/storico"
              className="menu-topbar"
              style={({ isActive }) => ({
                color: isActive ? "#ffffff" : "#545e6f",
                background: isActive ? "#1b4389" : null,
                fontWeight: isActive ? "800" : "400",
              })}
            >
              <div className="menu-topbar">
                <span className="material-symbols-outlined">receipt_long</span>
              </div>
            </NavLink>
          </>
        
        {/* {!props.iscarrello ? (
        <> */}

        <NavLink
          to="/carrello"
          className="menu-topbar"
          style={({ isActive }) => ({
            color: isActive ? "#fff" : "#545e6f",
            background: isActive ? "#1b4389" : null,
          })}
        >
          <div className="menu-topbar">
            <span className="material-symbols-outlined">shopping_cart</span>
            <label>{qty_prodotti}</label>
          </div>
        </NavLink>
        {/*  </>
      ) : null} */}
        {props.iscarrello && qty_prodotti > 0 ? (
          <a
            href="#fakeLink"
            onClick={() => props.onSend()}
            className="btn purple"
          >
            Invia
          </a>
        ) : null}
      </div>
    </div>
  );
};
export default Topbar;
