import React from "react";
import "../style/BaseInfo.css";

const VerdureInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
    let ing= props.ingredienti.find((p) => p.id_ingredienti == event.target.value)
    var updatedList = [...props.formData.verdure];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.verdure,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
        
      ];
    props.setFormData({ ...props.formData, verdure: updatedList,totale:Number(props.formData.totale)+Number(ing.prezzo)} );

    } else {
      updatedList.splice(
        props.formData.verdure.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
    props.setFormData({ ...props.formData, verdure: updatedList,totale:props.formData.totale-ing.prezzo} );

    }
  };

  

  return (
    <div className="container-fornoinfo">
      {checkList.map((item, index) => {
        if (
          item.categorie_ingredienti.nome == "Verdure"
        ) {
          return (
            <div className="ingr" key={index}>
              <input
               
                value={item.id_ingredienti}
                type="checkbox"
                onChange={handleCheck}
                checked={
                  !props.formData.verdure.find(
                    (p) => p.id_ingredienti == item.id_ingredienti
                  )
                    ? false
                    : true
                }
              />
              {item.nome} ({item.prezzo} €)
            </div>
          );
        }
      })}
    </div>
  );
};
export default VerdureInfo;
