import React, { useState } from "react";
import "../style/MySelect.css";

const SelectCategorie = ({ formData, setFormData ,categorieall}) => {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="select">
      <select
        className="select"
        aria-label="Default select example"
        onChange={(e) =>
          setFormData({ ...formData, id_categoria: e.target.value })
        }
        value={formData.id_categoria}
       
      >
        <option value="-1">-- Seleziona una categoria --</option>
        {categorieall.map((categoria) => (
          <option key={categoria.id_categoria} value={categoria.id_categoria}>
            {categoria.nome}
          </option>
        ))}
      </select>
     
    </div>
  );
};
export default SelectCategorie;
