import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style/App.css";

import Products from "./pages/Products";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PrivateRoutes from "./components/PrivateRoutes";
import Layout from "./components/Layout";
import ApriTavolo from "./pages/ApriTavolo";
import NotFound from "./components/NotFound";
import Portate from "./pages/Portate";
import Ordini from "./pages/Ordini";
import Offerte from "./pages/Offerte";
import Storico from "./pages/Storico";
import PizzaStepper from "./pages/PizzaStepper";
import HamburgerStepper from "./pages/HamburgerStepper";
import Home from "./pages/Home";
import { useDispatch } from "react-redux";
import Pusher from "pusher-js";
import { useEffect } from "react";
import {
  ATTESANUOVO,
  attesanuovo,
  get_categorie,
  logincheck,
  LOGIN_CHECK,
  login_fail,
  login_start,
  login_success,
  logout,
  onClear,
} from "./store/actions/login";
import PersistLogin from "./components/PersistLogin";
import PersistLogin2 from "./components/PersistLogin2";
import {
  recupera_carrello,
  set_coperti,
  set_ordine,
  set_totale_generale,
} from "./store/actions/ordini";
import {
  getSession,
  inizializzastorage,
  setAttesaNuovo,
  setCarrello,
} from "./utils/session";
import { Navigate } from "react-router-dom";
import { node } from "./Axios";
import Carrello from "./pages/Carrello";
import Spinner from "./components/Spinner";
import Piatti from "./pages/Piatti";
import Ingredienti from "./pages/Ingredienti";
import Ingrediente from "./pages/Ingrediente";
import Menu from "./pages/Menu";
import MenuStepper from "./pages/MenuStepper";
import LogoutMassivo from "./pages/LogoutMassivo";
import PiadinaStepper from "./pages/PiadinaStepper";
import Qrmenu from "./pages/Qrmenu";
import Qrapp from "./pages/Qrapp";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    Pusher.logToConsole = true;

    var pusher = new Pusher("335857782fa9261e9191", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("message");
    channel.bind("stampantioff", function (data) {
     
        alert(JSON.stringify(data.message));
   
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  useEffect(() => {
    async function fetchlogin() {
      dispatch(logincheck());
    }
    fetchlogin();
  }, []);
   useEffect(() => {
    async function fetch_get_categorie() {
      await dispatch(get_categorie());
    }
    fetch_get_categorie();
  }, []);
  const isLogin = useSelector((state) => state.login.isLogin);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);

  /*   console.log(isLogin); */

  return (
    <div className="App">
      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <Router>
        <Routes>
          {
            <Route path="/" element={<Layout isLogin={isLogin} />}>
              <Route path="login" element={<Login isLogin={isLogin} />} />
              <Route path="*" element={<NotFound />} />

              <Route element={<PrivateRoutes isLogin={isLogin} />}>
                <Route element={<Home />} path="/" />
                <Route element={<Storico />} path="/storicoclose" exact />

                <Route element={<Ordini />} path="/ordini" />
               
                <Route element={<PersistLogin id_ruolo={id_ruolo} />}>
                <Route element={<Carrello />} path="/carrello" />
                  <Route
                    element={<Products />}
                    path="/products/:ordine/:id/:flag"
                  />
                  <Route element={<ApriTavolo />} path="/apritavolo" />
                  <Route element={<Storico />} path="/storico" exact />
                  <Route element={<Offerte />} path="/offerte/:ordine/:id" exact />
                  <Route
                    element={<PizzaStepper />}
                    path="/pizzastepper/:id/:uui"
                    exact
                  /><Route
                  element={<PiadinaStepper />}
                  path="/piadinastepper/:id/:uui"
                  exact
                />
                  <Route
                    element={<HamburgerStepper />}
                    path="/hamburgerstepper/:id/:uui"
                    exact
                  />
                </Route>
                <Route element={<PersistLogin2 id_ruolo={id_ruolo} />}>
                  
                  <Route element={<Portate />} path="/portate" />
                  <Route element={<Piatti />} path="/piatto/:id" />
                  <Route element={<Ingredienti />} path="/ingredienti" />
                  <Route element={<Ingrediente />} path="/ingrediente/:id" />
                  <Route element={<Menu />} path="/menu" />
                  <Route element={<MenuStepper />} path="/menustepper/:id" />
                  <Route element={<Qrmenu />} path="/qrmenu" />
                  <Route element={<Qrapp />} path="/qrapp" />
                </Route>
                <Route element={<Logout />} path="/logout" />
                <Route element={<LogoutMassivo />} path="/logoutmassivo" />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          }
        </Routes>
      </Router>
      {/*   )} */}
    </div>
  );
}

export default App;
