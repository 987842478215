import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { node } from "../Axios";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";

import { loading_login, logout } from "../store/actions/login";
import {
  get_ordini_aperti_sala,
  send_ordine_success,
  set_coperti,
  set_ordine,
  set_storico_ordine,
  set_tavolo,
  set_totale_generale,
} from "../store/actions/ordini";
import "../style/Ordini.css";
import Tabs from "../components/tabcomponents/Tabs";
import Pusher from "pusher-js";

const Ordini = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordini = useSelector((state) => state.ordini.ordini);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);
  useEffect(() => {
    Pusher.logToConsole = true;

    var pusher = new Pusher("335857782fa9261e9191", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("message");
    channel.bind("aggiornati", function (data) {
      async function fetchattesanuoo() {
        dispatch(loading_login(true));

        dispatch(get_ordini_aperti_sala());
        dispatch(loading_login(false));
      }
      fetchattesanuoo();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  useEffect(() => {
    async function fetchordini() {
      await dispatch(set_ordine(-1));
      await dispatch(set_storico_ordine(0));

    }

    fetchordini();
  }, []);

  
  /*  const chiudiordine = async (id_ordine) => {
    try {
      await dispatch(loading_login(true));
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {
        id_ordine: id_ordine,
      };
      const res = await node.post(
        "/api/ordini/chiudiordine",
        bodyParameters,
        config
      );
      await dispatch(get_ordini_aperti_sala_aperti());
      await dispatch(loading_login(false));
    } catch (error) {
      if (error.response.status == "401") {
        dispatch(logout());
      }
      if (error.response.status == "422") {
        console.log(error.response);
      }
    }
  }; */

  /*  const ordina = async (id_ordine, coperti, tot_generale, tavolo) => {
    await dispatch(set_tavolo(tavolo));
    await dispatch(set_ordine(id_ordine));
    await dispatch(set_totale_generale(tot_generale));
    await dispatch(set_coperti(coperti));
    navigate(`../offerte/${id_ordine}/1`);
  }; */
  return (
    <div className="container-ordini">
      {id_ruolo != 1 ? (
        <div className="apritavolo">
          <a
            href="#fakeLink"
            onClick={() => navigate("../apritavolo")}
            className="btn purple"
          >
            Apri Tavolo
          </a>
        </div>
      ) : null}

      <Tabs />
    </div>
  );
};

export default Ordini;
