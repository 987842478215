import React from "react";
import "../style/Table.css";

const TableIngredienti = ({
  ingredienti,
  modifica,
  toggle,
 /*  totalPage,
  currentPage,
  setCurrentPage,
  setOffset,
  limit, */
}) => {
/*   let itemList = [];
  const inserisciPagine = () => {
    for (let i = 1; i <= totalPage; i++) {
      if (i == currentPage) {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn"
          >
            {i}
          </a>
        );
      } else {
        itemList.push(
          <a
            kei={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
              setOffset(limit * i - limit);
            }}
            className="btn purple"
          >
            {i}
          </a>
        );
      }
    }
    return itemList;
  }; */

  return (
    <div className="table">
      <section>
        <header className="header">
          <div className="col int">Categorie</div>
          <div className="col int">Nome</div>
          <div className="col int">Prezzo(€)</div>
          <div className="col int">Action</div>
        </header>

        {ingredienti.map((ingrediente, index) => {
          return (
            <div className="row" key={index}>
              <div className="col">
                {ingrediente.categorie_ingredienti.nome}
              </div>
              <div className="col">{ingrediente.nome}</div>

              <div className="col">{ingrediente.prezzo}</div>

              <div className="col">
                <a
                  href="#fakeLink"
                  onClick={() => modifica(ingrediente.id_ingredienti)}
                  className="btn purple"
                >
                  Modifica
                </a>
                <a
                  href="#fakeLink"
                  onClick={() =>
                    toggle(ingrediente.id_ingredienti, ingrediente.stato)
                  }
                  className="btn purple"
                >
                  {ingrediente.stato == 0 ? "Attiva" : "Disattiva"}
                </a>
              </div>
            </div>
          );
        })}
      </section>

     {/*  <div className="nume-page"> */}
        {/* <div className="pulsanti">Prev</div> */}
               {/*  {totalPage > 0 ? inserisciPagine() : null} */}
        
        {/* { itemList.map((item, index) => {
           console.log("ciao",item)
        })} */}
       {/*  <div className="pulsanti">Next</div> */}
     {/*  </div> */}
    </div>
  );
};

export default TableIngredienti;
