import { node } from "../../Axios";
import { getSession, removecarrello } from "../../utils/session";
import { logout } from "./login";

export const CARRELLO_START = "CARRELLO_START";
export const CARRELLO_SUCCESS = "CARRELLO_SUCCESS";
export const CARRELLO_FAIL = "CARRELLO_FAIL";

export const GET_ORDINI_START = "GET_ORDINI_START";
export const GET_ORDINI_SUCCESS = "GET_ORDINI_SUCCESS";
export const GET_ORDINI_FAIL = "GET_ORDINI_FAIL";

export const ELIMINA_PRODOTTO_CART = "ELIMINA_PRODOTTO_CART";
export const AGGIUNGI_INCREMENTA_PRODOTTO_CART =
  "AGGIUNGI_INCREMENTA_PRODOTTO_CART";
export const DECREMENTA_PRODOTTO_CART = "DECREMENTA_PRODOTTO_CART";
export const RECUPERA_CARRELLO = "RECUPERA_CARRELLO";

export const INIZIALIZE = "INIZIALIZE";
export const SET_COPERTI = "SET_COPERTI";

export const GET_PRODOTTIBYCATEGORIA_START = "GET_PRODOTTIBYCATEGORIA_START";
export const GET_PRODOTTIBYCATEGORIA_SUCCESS =
  "GET_PRODOTTIBYCATEGORIA_SUCCESS";
export const GET_PRODOTTIBYCATEGORIA_FAIL = "GET_PRODOTTIBYCATEGORIA_FAIL";

export const GET_CATEGORIEALL_START = "GET_CATEGORIEALL_START";
export const GET_CATEGORIEALL_SUCCESS = "GET_CATEGORIEALL_SUCCESS";
export const GET_CATEGORIEALL_FAIL = "GET_CATEGORIEALL_FAIL";

export const GET_PRODOTTI_START = "GET_PRODOTTI_START";
export const GET_PRODOTTI_SUCCESS = "GET_PRODOTTI_SUCCESS";
export const GET_PRODOTTI_FAIL = "GET_PRODOTTI_FAIL";

export const AZZERA_PRODOTTI = "AZZERA_PRODOTTI";

export const SEND_ORDINE_START = "SEND_ORDINE_START";
export const SEND_ORDINE_SUCCESS = "SEND_ORDINE_SUCCESS";
export const SEND_ORDINE_FAIL = "SEND_ORDINE_FAIL";

export const SET_TOTALE_GENERALE = "SET_TOTALE_GENERALE";
export const ISCARRELLO = "ISCARRELLO";
export const SET_ORDINE = "SET_ORDINE";

export const GET_INGREDIENTI_START = "GET_INGREDIENTI_START";
export const GET_INGREDIENTI_SUCCESS = "GET_INGREDIENTI_SUCCESS";
export const GET_INGREDIENTI_FAIL = "GET_INGREDIENTI_FAIL";

export const GET_CATEGORIE_INGREDIENTI_START =
  "GET_CATEGORIE_INGREDIENTI_START";
export const GET_CATEGORIE_INGREDIENTI_SUCCESS =
  "GET_CATEGORIE_INGREDIENTI_SUCCESS";
export const GET_CATEGORIE_INGREDIENTI_FAIL = "GET_CATEGORIE_INGREDIENTI_FAIL";

export const GET_INGREDIENTEBYID_START = "GET_INGREDIENTEBYID_START";
export const GET_INGREDIENTEBYID_SUCCESS = "GET_INGREDIENTEBYID_SUCCESS";
export const GET_INGREDIENTEBYID_FAIL = "GET_INGREDIENTEBYID_FAIL";

export const GET_OFFERTE_START = "GET_OFFERTE_START";
export const GET_OFFERTE_SUCCESS = "GET_OFFERTE_SUCCESS";
export const GET_OFFERTE_FAIL = "GET_OFFERTE_FAIL";

export const SALVA_PRODOTTO_START = "SALVA_PRODOTTO_START";
export const SALVA_PRODOTTO_SUCCESS = "SALVA_PRODOTTO_SUCCESS";
export const SALVA_PRODOTTO_FAIL = "SALVA_PRODOTTO_FAIL";

export const SALVA_INGREDIENTE_START = "SALVA_INGREDIENTE_START";
export const SALVA_INGREDIENTE_SUCCESS = "SALVA_INGREDIENTE_SUCCESS";
export const SALVA_INGREDIENTE_FAIL = "SALVA_INGREDIENTE_FAIL";

export const MODIFICA_PRODOTTO_START = "MODIFICA_PRODOTTO_START";
export const MODIFICA_PRODOTTO_SUCCESS = "MODIFICA_INGREDIENTE_SUCCESS";
export const MODIFICA_PRODOTTO_FAIL = "MODIFICA_PRODOTTO_FAIL";

export const MODIFICA_INGREDIENTE_START = "MODIFICA_INGREDIENTE_START";
export const MODIFICA_INGREDIENTE_SUCCESS = "MODIFICA_INGREDIENTE_SUCCESS";
export const MODIFICA_INGREDIENTE_FAIL = "MODIFICA_INGREDIENTE_FAIL";

export const GET_STORICO_ORDINi_START = "GET_STORICO_ORDINi_START";
export const GET_STORICO_ORDINi_SUCCESS = "GET_STORICO_ORDINi_SUCCESS";
export const GET_STORICO_ORDINi_FAIL = "GET_STORICO_ORDINi_FAIL";

export const GET_PRODOTTO_BYID_START = "GET_PRODOTTO_BYID_START";
export const GET_PRODOTTO_BYID_SUCCESS = "GET_PRODOTTO_BYID_SUCCESS";
export const GET_PRODOTTO_BYID_FAIL = "GET_PRODOTTO_BYID_FAIL";
export const RESET_PRODOTTO = "RESET_PRODOTTO";
export const RESET_INGREDIENTE = "RESET_INGREDIENTE";

export const SALVA_MENU_START = "SALVA_MENU_START";
export const SALVA_MENU_SUCCESS = "SALVA_MENU_SUCCESS";
export const SALVA_MENU_FAIL = "SALVA_MENU_FAIL";

export const MODIFICA_MENU_START = "MODIFICA_MENU_START";
export const MODIFICA_MENU_SUCCESS = "MODIFICA_MENU_SUCCESS";
export const MODIFICA_MENU_FAIL = "MODIFICA_MENU_FAIL";

export const GET_MENU_START = "GET_MENU_START";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAIL = "GET_MENU_FAIL";
export const SET_TAVOLO = "SET_TAVOLO";

export const SET_STORICO_ORDINE = "SET_STORICO_ORDINE";
export const RESET_PRODOTTI = "RESET_PRODOTTI";

export const send_ordine = (carrello, totale) => {
  return async (dispatch) => {
    await dispatch(send_ordine_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        carrello: carrello,
        totale: totale.toFixed(2),
      };
      const res = await node.post(
        "/api/ordini/inviaordine",
        bodyParameters,
        config
      );
      await removecarrello();
      await dispatch(set_totale_generale(res.data.oldtotale + totale));

      await dispatch(send_ordine_success());
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
    }
  };
};
export const send_ordine_start = () => {
  return {
    type: SEND_ORDINE_START,
  };
};
export const send_ordine_success = () => {
  return {
    type: SEND_ORDINE_SUCCESS,
  };
};
export const send_ordine_fail = () => {
  return {
    type: SEND_ORDINE_FAIL,
  };
};

export const get_offerte = (id) => {
  return async (dispatch) => {
    await dispatch(get_offerte_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
      };
      const res = await node.post(
        "/api/prodotti/getofferte",
        bodyParameters,
        config
      );

      await dispatch(get_offerte_success(res));
    } catch (error) {
      await dispatch(get_offerte_fail());
      await dispatch(logout());
    }
  };
};
export const get_offerte_start = () => {
  return {
    type: GET_OFFERTE_START,
  };
};
export const get_offerte_success = (res) => {
  return {
    type: GET_OFFERTE_SUCCESS,
    res,
  };
};
export const get_offerte_fail = () => {
  return {
    type: GET_OFFERTE_FAIL,
  };
};

export const get_prodotto_byid = (id) => {
  return async (dispatch) => {
    await dispatch(get_prodotto_byid_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_prodotto: id,
      };
     
      const res = await node.post(
        "/api/prodotti/getprodottobyid",
        bodyParameters,
        config
      );
      console.log("prodotto by id",res)
      await dispatch(get_prodotto_byid_success(res));
    } catch (error) {
      console.log("error", error);

      await dispatch(get_prodotto_byid_fail());
      await dispatch(logout());
    }
  };
};
export const get_prodotto_byid_start = () => {
  return {
    type: GET_PRODOTTO_BYID_START,
  };
};
export const get_prodotto_byid_success = (res) => {
  return {
    type: GET_PRODOTTO_BYID_SUCCESS,
    res,
  };
};
export const reset_prodotto = () => {
  return {
    type: RESET_PRODOTTO,
  };
};
export const reset_prodotti = () => {
  return {
    type: RESET_PRODOTTI,
  };
};
export const reset_ingrediente = () => {
  return {
    type: RESET_INGREDIENTE,
  };
};
export const get_prodotto_byid_fail = () => {
  return {
    type: GET_PRODOTTO_BYID_FAIL,
  };
};

export const get_storico_ordini = (id) => {
  return async (dispatch) => {
    await dispatch(get_storico_ordini_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_ordine: id,
      };
      const res = await node.post(
        "/api/ordini/getstoricoordinecamerierecassa",
        bodyParameters,
        config
      );
      /* if (res.data.storico.length > 0) {
        await dispatch(set_storico_ordine_aperto(1));
      } */
      await dispatch(get_storico_ordini_success(res));
    } catch (error) {
      await dispatch(get_storico_ordini_fail());
      await dispatch(logout());
    }
  };
};

export const get_storico_ordini_start = () => {
  return {
    type: GET_STORICO_ORDINi_START,
  };
};
export const get_storico_ordini_success = (res) => {
  return {
    type: GET_STORICO_ORDINi_SUCCESS,
    res,
  };
};
export const get_storico_ordini_fail = () => {
  return {
    type: GET_STORICO_ORDINi_FAIL,
  };
};
export const get_ordini_aperti_sala = () => {
  return async (dispatch) => {
    await dispatch(get_ordini_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getordini_aperti_sala",
        bodyParameters,
        config
      );
      await removecarrello();
      //console.log("ordini", res);
      //await dispatch(set_totale_generale(res.data.oldtotale+totale));

      await dispatch(get_ordini_success(res));
    } catch (error) {
      console.log(error);
      await dispatch(get_ordini_fail());
    }
  };
};
export const get_ordini_chiusi_sala = () => {
  return async (dispatch) => {
    await dispatch(get_ordini_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getordini_chiusi_sala",
        bodyParameters,
        config
      );
      await removecarrello();
      //console.log("ordini", res);
      //await dispatch(set_totale_generale(res.data.oldtotale+totale));

      await dispatch(get_ordini_success(res));
    } catch (error) {
      console.log(error);
      await dispatch(get_ordini_fail());
    }
  };
};
export const get_ordini_start = () => {
  return {
    type: GET_ORDINI_START,
  };
};
export const get_ordini_success = (res) => {
  return {
    type: GET_ORDINI_SUCCESS,
    res,
  };
};
export const get_ordini_fail = () => {
  return {
    type: GET_ORDINI_FAIL,
  };
};

export const send_ordine_cameriere_cassa = (carrello, totale, id_ordine) => {
  return async (dispatch) => {
    await dispatch(send_ordine_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_ordine: id_ordine,
        carrello: carrello,
        totale: totale.toFixed(2),
      };
      const res = await node.post(
        "/api/ordini/inviaordinecamerierecassa",
        bodyParameters,
        config
      );
      await removecarrello();
      //  console.log(res);
      await dispatch(set_totale_generale(res.data.oldtotale + totale));
      await dispatch(set_storico_ordine(1));
      await dispatch(send_ordine_success());
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
      await dispatch(logout());
    }
  };
};

export const getcarrello = () => {
  return async (dispatch) => {
    await dispatch(getcarrello_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getcarrello",
        bodyParameters,
        config
      );

      await dispatch(getcarrello_success(res));
    } catch (error) {
      await dispatch(get_carrello_fail());
    }
  };
};
export const getcarrello_start = () => {
  return {
    type: CARRELLO_START,
  };
};
export const getcarrello_success = (res) => {
  return {
    type: CARRELLO_SUCCESS,
    res,
  };
};
export const get_carrello_fail = () => {
  return {
    type: CARRELLO_FAIL,
  };
};
export const get_prodottibycategoria = (id) => {
  return async (dispatch) => {
    await dispatch(get_prodottibycategoria_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
      };
      const res = await node.post(
        "/api/prodotti/getprodottibycategoria",
        bodyParameters,
        config
      );
      //   console.log(res);
      await dispatch(get_prodottibycategoria_success(res));
    } catch (error) {
      await dispatch(get_prodottibycategoria_fail());
    }
  };
};
export const get_prodottibycategoria_start = () => {
  return {
    type: GET_PRODOTTIBYCATEGORIA_START,
  };
};
export const get_prodottibycategoria_success = (res) => {
  return {
    type: GET_PRODOTTIBYCATEGORIA_SUCCESS,
    res,
  };
};
export const get_prodottibycategoria_fail = () => {
  return {
    type: GET_PRODOTTIBYCATEGORIA_FAIL,
  };
};

export const get_categorieall = () => {
  return async (dispatch) => {
    await dispatch(get_categorieall_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/categorie/getcategorieall",
        bodyParameters,
        config
      );
      await dispatch(get_categorieall_success(res));
    } catch (error) {
      await dispatch(get_categorieall_fail());
    }
  };
};
export const get_categorieutili = () => {
  return async (dispatch) => {
    await dispatch(get_categorieall_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/categorie/getcategorieutili",
        bodyParameters,
        config
      );
      await dispatch(get_categorieall_success(res));
    } catch (error) {
      await dispatch(get_categorieall_fail());
    }
  };
};
export const get_categorieall_start = () => {
  return {
    type: GET_CATEGORIEALL_START,
  };
};
export const get_categorieall_success = (res) => {
  return {
    type: GET_CATEGORIEALL_SUCCESS,
    res,
  };
};
export const get_categorieall_fail = () => {
  return {
    type: GET_CATEGORIEALL_FAIL,
  };
};
export const get_prodotti = (offset, limit) => {
  return async (dispatch) => {
    await dispatch(get_prodotti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        limit: limit,
        offset: offset,
      };
      const res = await node.post(
        "/api/prodotti/getprodotti",
        bodyParameters,
        config
      );
       console.log("prodotti",res);
      await dispatch(get_prodotti_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
    }
  };
};
export const get_prodotti_start = () => {
  return {
    type: GET_PRODOTTI_START,
  };
};
export const get_prodotti_success = (res) => {
  return {
    type: GET_PRODOTTI_SUCCESS,
    res,
  };
};
export const get_prodotti_fail = () => {
  return {
    type: GET_PRODOTTI_FAIL,
  };
};
export const inizialize = () => {
  return {
    type: INIZIALIZE,
  };
};
export const elimina_prodotto_cart = (card) => {
  return {
    type: ELIMINA_PRODOTTO_CART,
    card,
  };
};

export const decrementa_prodotto_cart = (card) => {
  return {
    type: DECREMENTA_PRODOTTO_CART,
    card,
  };
};
export const aggiungi_incrementa_prodotto_cart = (card) => {
  return {
    type: AGGIUNGI_INCREMENTA_PRODOTTO_CART,
    card,
  };
};
export const recupera_carrello = (carrello, qty, totale) => {
  return {
    type: RECUPERA_CARRELLO,
    carrello,
    qty,
    totale,
  };
};
export const set_coperti = (coperti) => {
  return {
    type: SET_COPERTI,
    coperti,
  };
};
export const set_totale_generale = (tot_generale) => {
  return {
    type: SET_TOTALE_GENERALE,
    tot_generale,
  };
};
export const set_ordine = (id_ordine) => {
  return {
    type: SET_ORDINE,
    id_ordine,
  };
};
export const azzera_prodotti = () => {
  return {
    type: AZZERA_PRODOTTI,
  };
};
export const set_iscarrello = (value) => {
  return {
    type: ISCARRELLO,
    value,
  };
};

export const get_ingredienti = (offset,limit) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        limit: limit,
        offset: offset,
      };
      const res = await node.post(
        "/api/ingredienti/getingredienti",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const cambia_stato_ingrediente = (id, stato) => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = { id_ingredienti: id, stato: stato };

      const res = await node.post(
        "/api/ingredienti/cambiastato",
        bodyParameters,
        config
      );
    } catch (error) {
      console.log(error);
      // await dispatch(logout());
    }
  };
};
export const cambia_stato_prodotti = (id, stato) => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = { id_prodotto: id, stato: stato };
      const res = await node.post(
        "/api/prodotti/cambiastato",
        bodyParameters,
        config
      );
    } catch (error) {
      console.log(error);
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_pizza_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpezzapersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_piadina_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpiadinapersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_hamburger_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperhamburgerpersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_start = () => {
  return {
    type: GET_INGREDIENTI_START,
  };
};
export const get_ingredienti_success = (res) => {
  return {
    type: GET_INGREDIENTI_SUCCESS,
    res,
  };
};
export const get_ingredienti_fail = () => {
  return {
    type: GET_INGREDIENTI_FAIL,
  };
};
export const get_ingredientebyid = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredientebyid_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_ingrediente: id,
      };
      const res = await node.post(
        "/api/ingredienti/getingredientebyid",
        bodyParameters,
        config
      );
      //  console.log(res);
      await dispatch(get_ingredientebyid_success(res));
    } catch (error) {
      await dispatch(get_ingredientebyid_fail());
      await dispatch(logout());
    }
  };
};

export const get_ingredientebyid_start = () => {
  return {
    type: GET_INGREDIENTEBYID_START,
  };
};
export const get_ingredientebyid_success = (res) => {
  return {
    type: GET_INGREDIENTEBYID_SUCCESS,
    res,
  };
};
export const get_ingredientebyid_fail = () => {
  return {
    type: GET_INGREDIENTEBYID_FAIL,
  };
};

export const get_categorie_ingredienti = (id) => {
  return async (dispatch) => {
    await dispatch(get_categorie_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getcategorieingredienti",
        bodyParameters,
        config
      );
      await dispatch(get_categorie_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_categorie_ingredienti_fail());
      await dispatch(logout());
    }
  };
};

export const get_categorie_ingredienti_start = () => {
  return {
    type: GET_CATEGORIE_INGREDIENTI_START,
  };
};
export const get_categorie_ingredienti_success = (res) => {
  return {
    type: GET_CATEGORIE_INGREDIENTI_SUCCESS,
    res,
  };
};
export const get_categorie_ingredienti_fail = () => {
  return {
    type: GET_CATEGORIE_INGREDIENTI_FAIL,
  };
};

export const salva_prodotto = (prodotto) => {
  return async (dispatch) => {
    await dispatch(salva_prodotto_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        nome: prodotto.nome,
        ingredienti_it: prodotto.ingredienti_it,
        ingredienti_en: prodotto.ingredienti_en,
        image: prodotto.image,
        id_categoria: prodotto.id_categoria,
        prezzo: prodotto.prezzo,
        prezzo_offerte: prodotto.prezzo_offerte,
        stato: prodotto.stato,
      };
      const res = await node.post(
        "/api/prodotti/aggiungiprodotto",
        bodyParameters,
        config
      );
      //   console.log(res);
      await dispatch(salva_prodotto_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(salva_prodotto_fail());
    }
  };
};
export const salva_prodotto_start = () => {
  return {
    type: SALVA_PRODOTTO_SUCCESS,
  };
};
export const salva_prodotto_success = () => {
  return {
    type: SALVA_PRODOTTO_SUCCESS,
  };
};
export const salva_prodotto_fail = () => {
  return {
    type: SALVA_PRODOTTO_FAIL,
  };
};

export const salva_ingrediente = (ingrediente) => {
  return async (dispatch) => {
    await dispatch(salva_ingrediente_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        nome: ingrediente.nome,
        prezzo: ingrediente.prezzo,
        unita_di_misura: ingrediente.unita_di_misura,
        id_categorie_ingredienti: ingrediente.id_categorie_ingredienti,
        categorie_scelte: ingrediente.categorie_scelte,
      };
      const res = await node.post(
        "/api/ingredienti/aggiungiingrediente",
        bodyParameters,
        config
      );
      //  console.log(res);
      await dispatch(salva_ingrediente_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(salva_ingrediente_fail());
    }
  };
};
export const salva_ingrediente_start = () => {
  return {
    type: SALVA_INGREDIENTE_SUCCESS,
  };
};
export const salva_ingrediente_success = () => {
  return {
    type: SALVA_INGREDIENTE_SUCCESS,
  };
};
export const salva_ingrediente_fail = () => {
  return {
    type: SALVA_INGREDIENTE_FAIL,
  };
};

export const modifica_prodotto = (prodotto) => {
  return async (dispatch) => {
    await dispatch(modifica_prodotto_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        nome: prodotto.nome,
        ingredienti_it: prodotto.ingredienti_it,
        ingredienti_en: prodotto.ingredienti_en,
        image: prodotto.image,
        id_categoria: prodotto.id_categoria,
        prezzo: prodotto.prezzo,
        prezzo_offerte: prodotto.prezzo_offerte,
        stato: prodotto.stato,
        id_prodotto: prodotto.id_prodotto,
      };
      const res = await node.post(
        "/api/prodotti/modificaprodotto",
        bodyParameters,
        config
      );
      //  console.log(res);
      await dispatch(modifica_prodotto_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(modifica_prodotto_fail());
    }
  };
};
export const modifica_prodotto_start = () => {
  return {
    type: MODIFICA_PRODOTTO_SUCCESS,
  };
};
export const modifica_prodotto_success = () => {
  return {
    type: MODIFICA_PRODOTTO_SUCCESS,
  };
};
export const modifica_prodotto_fail = () => {
  return {
    type: MODIFICA_PRODOTTO_FAIL,
  };
};

export const modifica_ingrediente = (ingrediente) => {
  return async (dispatch) => {
    await dispatch(modifica_ingrediente_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        id_ingredienti: ingrediente.id_ingredienti,
        nome: ingrediente.nome,
        prezzo: ingrediente.prezzo,
        unita_di_misura: ingrediente.unita_di_misura,
        id_categorie_ingredienti: ingrediente.id_categorie_ingredienti,
        categorie_scelte: ingrediente.categorie_scelte,
      };
      const res = await node.post(
        "/api/ingredienti/modificaingrediente",
        bodyParameters,
        config
      );
      // console.log(res);
      await dispatch(modifica_ingrediente_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(modifica_ingrediente_fail());
    }
  };
};
export const modifica_ingrediente_start = () => {
  return {
    type: MODIFICA_INGREDIENTE_SUCCESS,
  };
};
export const modifica_ingrediente_success = () => {
  return {
    type: MODIFICA_INGREDIENTE_SUCCESS,
  };
};
export const modifica_ingrediente_fail = () => {
  return {
    type: MODIFICA_INGREDIENTE_FAIL,
  };
};

export const get_menu = (offset, limit) => {
  return async (dispatch) => {
    await dispatch(get_menu_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        limit: limit,
        offset: offset,
      };
      const res = await node.post(
        "/api/prodotti/getmenu",
        bodyParameters,
        config
      );
      //   console.log(res);
      await dispatch(get_menu_success(res));
    } catch (error) {
      await dispatch(get_menu_fail());
      await dispatch(logout());
    }
  };
};

export const get_menu_start = () => {
  return {
    type: GET_MENU_START,
  };
};
export const get_menu_success = (res) => {
  return {
    type: GET_MENU_SUCCESS,
    res,
  };
};
export const get_menu_fail = () => {
  return {
    type: GET_MENU_FAIL,
  };
};

export const salva_menu = (prodotto) => {
  return async (dispatch) => {
    await dispatch(salva_menu_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        nome: prodotto.nome,
        image: prodotto.image,
        prezzo: prodotto.prezzo,
        antipasti_scelti: prodotto.antipasti_scelti,
        primi_scelti: prodotto.primi_scelti,
        secondi_scelti: prodotto.secondi_scelti,
        contorni_scelti: prodotto.contorni_scelti,
        dolci_scelti: prodotto.dolci_scelti,
        bevande_scelti: prodotto.bevande_scelti,
      };
      const res = await node.post(
        "/api/prodotti/aggiungimenu",
        bodyParameters,
        config
      );
      // console.log(res);
      await dispatch(salva_menu_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(salva_menu_fail());
    }
  };
};
export const salva_menu_start = () => {
  return {
    type: SALVA_MENU_SUCCESS,
  };
};
export const salva_menu_success = () => {
  return {
    type: SALVA_MENU_SUCCESS,
  };
};
export const salva_menu_fail = () => {
  return {
    type: SALVA_MENU_FAIL,
  };
};

export const modifica_menu = (prodotto) => {
  return async (dispatch) => {
    await dispatch(modifica_menu_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: {
          Authorization: `Bearer ${sessione.token}`,
        },
      };
      const bodyParameters = {
        id_prodotto: prodotto.id_prodotto,
        nome: prodotto.nome,
        image: prodotto.image,
        prezzo: prodotto.prezzo,
        antipasti_scelti: prodotto.antipasti_scelti,
        primi_scelti: prodotto.primi_scelti,
        secondi_scelti: prodotto.secondi_scelti,
        contorni_scelti: prodotto.contorni_scelti,
        dolci_scelti: prodotto.dolci_scelti,
        bevande_scelti: prodotto.bevande_scelti,
      };
      const res = await node.post(
        "/api/prodotti/modificamenu",
        bodyParameters,
        config
      );
      // console.log(res);
      await dispatch(modifica_menu_success());
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(modifica_menu_fail());
    }
  };
};
export const modifica_menu_start = () => {
  return {
    type: MODIFICA_MENU_SUCCESS,
  };
};
export const modifica_menu_success = () => {
  return {
    type: MODIFICA_MENU_SUCCESS,
  };
};
export const modifica_menu_fail = () => {
  return {
    type: MODIFICA_MENU_FAIL,
  };
};
export const set_storico_ordine = (value) => {
  return {
    type: SET_STORICO_ORDINE,
    value
  };
};
export const set_tavolo = (value) => {
  return {
    type: SET_TAVOLO,
    value,
  };
};
