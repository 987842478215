import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { node } from "../../Axios";
import { loading_login, logout } from "../../store/actions/login";
import {
  get_ordini_aperti_sala,
  send_ordine_success,
  set_coperti,
  set_ordine,
  set_storico_ordine,
  set_tavolo,
  set_totale_generale,
} from "../../store/actions/ordini";
import { getSession } from "../../utils/session";
import Spinner from "../Spinner";
import Table from "../TableOrdiniAperti";

const FirstTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordini = useSelector((state) => state.ordini.ordini);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);
 
  useEffect(() => {
    async function fetchordini() {
      await dispatch(loading_login(true));
      await dispatch(get_ordini_aperti_sala());
      await dispatch(send_ordine_success());
    

      await dispatch(loading_login(false));
    }

    fetchordini();
  }, []);
  const chiudiordine = async (id_ordine) => {
    try {
      await dispatch(loading_login(true));
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {
        id_ordine: id_ordine,
      };
      const res = await node.post(
        "/api/ordini/chiudiordine",
        bodyParameters,
        config
      );
      await dispatch(get_ordini_aperti_sala());
      await dispatch(loading_login(false));
    } catch (error) {
      if (error.response.status == "401") {
        dispatch(logout());
      }
      if (error.response.status == "422") {
        console.log(error.response);
      }
    }
  };
  const ordina = async (id_ordine, coperti, tot_generale, tavolo) => {
    console.log("sono qui")
    await dispatch(set_storico_ordine(1));

    await dispatch(set_tavolo(tavolo));
    await dispatch(set_ordine(id_ordine));
    await dispatch(set_totale_generale(tot_generale));
    await dispatch(set_coperti(coperti));

    navigate(`../offerte/${id_ordine}/1`);
  };


  return (
    <div className="content-tab">
      <div className="titolo-tab">Ordini Aperti!</div>
      {loading || loading2 ? (
        <Spinner />
      ) : ordini != 0 ? (
        <div className="contenuto-ordini">
          <Table
            ordini={ordini}
            chiudiordine={chiudiordine}
            ordina={ordina}
            ruolo={id_ruolo}
          />
        </div>
      ) : (
        "Non sono ancora presenti ordini"
      )}
    </div>
  );
};
export default FirstTab;
