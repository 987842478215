import React, { useEffect, useState } from "react";
import { node } from "../Axios";
import { getSession } from "../utils/session";
import "../style/Qrmenu.css";

const Qrapp = () => {
  const [qrCode, setQrCode] = useState("");
  
  useEffect(() => {
    async function fetchqr() {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/prodotti/qrapptablet",
        bodyParameters,
        config
      );
      setQrCode(res.data);
     
    }
    fetchqr();
  }, []);
  const downloadQrCode = () => {
    const element = document.createElement("a");
    element.href = `data:image/png;base64,${qrCode}`;
    element.download = "qrcode.png";
    element.click();
  };
  return (
    <div className="qr">
      {qrCode && (
        <div className="qr">
         
          <img
            className="qrim"
            src={`data:image/png;base64,${qrCode}`}
            alt="QR Code"
          />
          <a href={`data:image/png;base64,${qrCode}`} download="qrcode.png">
          Download QR Code
          </a>
        </div>

        
        
      )}
    </div>
  );
};

export default Qrapp;
