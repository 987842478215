import React, { useEffect, useState } from "react";
import Cards from "../components/Cards";
import Modal from "../pages/ModalProdotto";
import "../style/Products.css";
import { useDispatch, useSelector } from "react-redux";
import {
  elimina_prodotto_cart,
  aggiungi_incrementa_prodotto_cart,
  decrementa_prodotto_cart,
  set_iscarrello,
  get_prodottibycategoria,
  get_ingredienti_per_pizza_personalizzata,
  get_ingredienti_per_piadina_personalizzata,
  get_ingredienti_per_hamburger_personalizzata,
} from "../store/actions/ordini";
import { setCarrello } from "../utils/session";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import Spinner from "../components/Spinner";
import Personalizzami from "../components/Personalizzami";
import CardMenu from "../components/CardMenu";
import uuid from "react-uuid";

const Products = () => {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);

  const [modal, setModal] = useState(false);
  const [datiModal, seDatiModal] = useState({
    card: "",
    onqty: "",
    id_prodotto_interno: "",
    prezzo: "",
  });
  const toggleModal =async (card, onqty, id_prodotto_interno, prezzo) => {
    setModal(!modal);
    seDatiModal({
      card: card,
      onqty: onqty,
      id_prodotto_interno: id_prodotto_interno,
      prezzo: prezzo,
    });
  };

  const iscarrello = false;
  const navigate = useNavigate();
  let { ordine, id, flag } = useParams();

  useEffect(() => {
    async function fetch_get_prodotti() {

      await dispatch(get_prodottibycategoria(id));
    }
    fetch_get_prodotti();
  }, [flag, ordine, id]);
  useEffect(() => {
    dispatch(set_iscarrello(false));
  }, []);

  const modalpass = (prodotto, qty, id_prodotto_interno, prezzo) => {
    return (
      <Modal
        toggleModal={toggleModal}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
      />
    );
  };

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const preparapizza = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_pizza_personalizzata());
    navigate(`/pizzastepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const preparapiadina = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_piadina_personalizzata());
    navigate(`/piadinastepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  const preparapanino = async (id_prodotto, id_prodotto_interno) => {
    await dispatch(get_ingredienti_per_hamburger_personalizzata());
    navigate(`/hamburgerstepper/${id_prodotto}/${id_prodotto_interno}`);
  };
  
  const handleIncrement = (card, qty, id_prodotto_interno, prezzo) => {
    if (id_prodotto_interno == 1) {
      id_prodotto_interno = uuid();
    }
    const p = {
      id_prodotto: card.id_prodotto,
      nome: card.nome,
      ingredienti_it: card.ingredienti_it,
      ingredienti_en: card.ingredienti_en,
      prezzo: prezzo,
      special: 0,
      newspecial: false,
      quantita: qty + 1,
      id_prodotto_interno: id_prodotto_interno,
    };

    dispatch(aggiungi_incrementa_prodotto_cart(p));
  };

  const handleDecrement = (card, qty, id_prodotto_interno, prezzo) => {
    const p = {
      id_prodotto: card.id_prodotto,
      nome: card.nome,
      ingredienti_it: card.ingredienti_it,
      ingredienti_en: card.ingredienti_en,
      special: 0,
      newspecial: false,
      id_prodotto_interno: id_prodotto_interno,
      prezzo: prezzo,
    };
    if (qty == 1) {
      dispatch(elimina_prodotto_cart(p));
    } else if (qty > 1) {
      p.quantita = qty - 1;
      dispatch(decrementa_prodotto_cart(p));
    }
  };
  let itemList = [];
  const map_card = () => {

    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";
      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;
          return false;
        }
        return true;
      });

      itemList.push(
        <Cards
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          onqty={qty}
          id_prodotto_interno={id_prodotto_interno}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
      );
    });

    return itemList;
  };
  const map_card2 = () => {
    //pizza personalizzata
    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";

      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;
          return false;
        }
        return true;
      });

      itemList.push(
        <Personalizzami
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          prepara={preparapizza}
          id_prodotto_interno={id_prodotto_interno}
        />
      );
    });

    return itemList;
  };
  const map_card5 = () => {
    //piadina personalizzata
    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";

      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;
          return false;
        }
        return true;
      });

      itemList.push(
        <Personalizzami
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          prepara={preparapiadina}
          id_prodotto_interno={id_prodotto_interno}
        />
      );
    });

    return itemList;
  };
  const map_card3 = () => {
    //hamburgher personalizzato

    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";

      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;

          return false;
        }
        return true;
      });

      itemList.push(
        <Personalizzami
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          prepara={preparapanino}
          id_prodotto_interno={id_prodotto_interno}
        />
      );
    });

    return itemList;
  };

  const map_card4 = () => {
    //console.log("prodotti", prodotti);

    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";
      let descrizione = "";
      prodotto.composizione_menus.forEach((cm, index) => {
        descrizione = descrizione + cm.Prodotti_Correlati.categorie.nome + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.nome + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.ingredienti_it + " ";
        descrizione = descrizione + cm.Prodotti_Correlati.ingredienti_en + " ";
      });
      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;

          return false;
        }
        return true;
      });
      prodotto.ingredienti_it = descrizione;
      itemList.push(
        <CardMenu
          toggleModal={toggleModal}
          key={index}
          card={prodotto}
          onqty={qty}
          id_prodotto_interno={id_prodotto_interno}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
      );
    });

    return itemList;
  };
  return (
    <div className="prodotti">
      {modal ? modalpass() : null}
      {loading || loading2 ? (
        <Spinner />
      ) : flag == 0 ? (
        map_card()
      ) : flag == 1 ? (
        map_card2()
      ) : flag == 2 ? (
        map_card3()
      ) : flag == 3 ? (
        map_card4()
      ) : flag == 4 ? (
        map_card5()
      ): (
        "ciao"
      )}
    </div>
  );
};

export default Products;
