import React, { useState } from "react";
import "../style/RadioButton.css";
const RadioButton = ({ prezzo, id, value, onChange, checked, text }) => {
  
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
    console.log(isActive);
  };
  return (
    <label
      htmlFor={id}
      classNam={`radio-label ${isActive ? "radio-selected" : ""}`}
      onClick={handleToggle}
    >
      <input
        className="radio-input"
        type="radio"
        prezzo={prezzo}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      {text}({prezzo} €)
    </label>
  );
};
export default RadioButton;
