import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { loading_login } from "../store/actions/login";
import {
  get_prodotti,
  modifica_menu,
  salva_menu,
} from "../store/actions/ordini";
import "../style/PizzaStepper.css";
import { setCarrello } from "../utils/session";
import AntipastiInfo from "../components/AntipastiInfo";
import PrimiInfo from "../components/PrimiInfo";
import SecondiInfo from "../components/SecondiInfo";
import ContorniInfo from "../components/ContorniInfo";
import DolciInfo from "../components/DolciInfo";
import BevandeInfo from "../components/BevandeInfo";
import PrezzoStatoInfo from "../components/PrezzoStatoInfo";
import Resizer from "react-image-file-resizer";


const MenuStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [railCards, setRailCards] = useState([]);

  useEffect(() => {
    async function fetch_get_prodotti() {
      await dispatch(loading_login(true));
      await dispatch(get_prodotti());
      await dispatch(loading_login(false));
    }
    fetch_get_prodotti();
  }, []);
  const { id } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const portate = useSelector((state) => state.ordini.portate);
  const prodotto = useSelector((state) => state.ordini.prodotto);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const [page, setPage] = useState(0);

  const [formData, setFormData] = useState({
    id_prodotto: id,
    nome: prodotto.length > 0 ? prodotto[0].prodottis[0].nome : "",
    prezzo: prodotto.length > 0 ? prodotto[0].prodottis[0].prezzo : 0,
    antipasti_scelti: [],
    primi_scelti: [],
    secondi_scelti: [],
    contorni_scelti: [],
    dolci_scelti: [],
    bevande_scelti: [],
    image: {},
  });
  console.log("fromdata",formData)
  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            setFormData({ ...formData, image: uri });
            console.log(uri);
          },

          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleRailCardCLick = (p) => {
    let id = Math.floor(Math.random() * 1000000);
    setRailCards([
      ...railCards,
      {
        value: "",
        fieldName: `railcard-${railCards.length + 1}`,
        count: 1,
        id,
        page: p,
      },
    ]);
  };

  const FormTitles = [
    "Scegli gli Antipasti",
    "Scegli i Primi",
    "Scegli i Secondi",
    "Scegli i Contorni",
    "Scegli i Dolci",
    "Scegli le Bevande",
    "Scegli i Dettagli",
  ];

  const salvaMenu = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(salva_menu(formData))) navigate("/menu");
    await dispatch(loading_login(false));
  };
  const modificaMenu = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(modifica_menu(formData))) navigate("/menu");
    await dispatch(loading_login(false));
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
          <AntipastiInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <PrimiInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <SecondiInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 3) {
      return (
        <>
          <ContorniInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 4) {
      return (
        <>
          <DolciInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 5) {
      return (
        <>
          <BevandeInfo
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    } else if (page === 6) {
      return (
        <>
          <PrezzoStatoInfo
            fileChangedHandler={fileChangedHandler}
            portate={portate}
            formData={formData}
            setFormData={setFormData}
            railCards={railCards}
            setRailCards={setRailCards}
            handleRailCardCLick={handleRailCardCLick}
            page={page}
          />
        </>
      );
    }
  };
  return (
    <div className="container-pizzastepper">
      <div className="progressbar">
        <div
          style={{
            width:
              page === 0
                ? "14.3%"
                : page == 1
                ? "28.6%"
                : page == 2
                ? "42.9%"
                : page == 3
                ? "57.2%"
                : page == 4
                ? "71.5%"
                : page == 5
                ? "85.8%"
                : "100%",
          }}
        ></div>
      </div>
      <div className="titolo-page">{FormTitles[page]} </div>
      {loading || loading2 ? (
        <Spinner />
      ) : (
        <div className="">{PageDisplay()}</div>
      )}
      <div className="ingredienti">{error != "" ? error : null}</div>

      <div className="pizza-btn-container">
        {page > 0 ? (
          <a
            href="#fakeLink"
            disabled={page == 0}
            onClick={() => {
              setPage((currPage) => currPage - 1);
              setError("");
            }}
            className="btn purple"
          >
            Indietro
          </a>
        ) : null}
        <a
          href="#fakeLink"
          onClick={() => {
            if (page === FormTitles.length - 1) {
              if (formData.nome != "" /*  || */ && formData.prezzo != "") {
                formData.id_prodotto == 0 ? salvaMenu() : modificaMenu();

                setError("");
              } else {
                setError("Il nome e la prezzo sono obbligatori");
              }
            } else {
              setPage((currPage) => currPage + 1);
              setError("");
            }
          }}
          className="btn purple"
        >
          {page === FormTitles.length - 1 ? "Conferma" : "Avanti"}
        </a>
      </div>
    </div>
  );
};

export default MenuStepper;
