import "../style/AntipastiInfo.css";

const PrimiInfo = (props) => {
  
  const handleSelectRailcard = (event) => {
    const index = event.target.getAttribute("data-index");
    let value = event.target.value;
    let piatto
    piatto = props.portate.find((p) => p.id_prodotto == value);
    if (piatto) {
      var updatedList = [...props.formData.primi_scelti, piatto];
      props.setFormData({ ...props.formData, primi_scelti: updatedList });
    }

    const updatedRailCards =
    props.railCards &&
    props.railCards.map((i) => {
        if (i.id === +index) {
          i.value = value;
        }
        return i;
      });
      props.setRailCards(updatedRailCards);
  };

  const handleRemoveRailcard = (id, value) => {
    const updatedRailCards = props.railCards && props.railCards.filter((r) => r.id !== id);
    var updatedList = [...props.formData.primi_scelti];
     updatedList.splice(
      props.formData.primi_scelti.find((p) => p.id_prodotto == value),
      1
    );
    props.setFormData({ ...props.formData, primi_scelti: updatedList });

    props.setRailCards(updatedRailCards);
  };

  return (
    <div className="conteiner-antipasti">
      <div className="piatti">
        {props.railCards &&
          props.railCards.map(({ value, fieldName, id, page }, index) => {
            if (page === props.page) {
                  return(<div key={id}>
                <select
                  value={value}
                  name={fieldName}
                  className="form-control passanger-dropdown-item--select w-70 mr-1"
                  data-index={id}
                  onChange={handleSelectRailcard}
                >
                  <option value="-1">-- Seleziona un Primo --</option>
                  {props.portate.map((portata, index) => {
                    if (portata.categorie.id_categoria == 3) {
                      return (
                        <option key={index} value={portata.id_prodotto}>
                          {portata.nome}
                        </option>
                      );
                    }
                  })}
                </select>

                <button
                  type="button"
                  className="btn red"
                  onClick={() => handleRemoveRailcard(id, value)}
                >
                  x
                </button>
              </div>)
              
            }
          })}
      </div>
      <button
        type="button"
        className="btn neutro"
        onClick={() => props.handleRailCardCLick(props.page)}
      >
        +
      </button>
    </div>
  );

};

export default PrimiInfo;
