import React, { useEffect, useState } from "react";
import Cards from "../components/Cards";

import Modal from "../pages/ModalProdotto";

import "../style/Offerte.css";
import { useDispatch, useSelector } from "react-redux";
import {
  elimina_prodotto_cart,
  aggiungi_incrementa_prodotto_cart,
  decrementa_prodotto_cart,
  set_iscarrello,
  get_offerte,
  get_storico_ordini,
} from "../store/actions/ordini";
import { setCarrello } from "../utils/session";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import Spinner from "../components/Spinner";

const Offerte = () => {
  const [modal, setModal] = useState(false);
  const [datiModal, seDatiModal] = useState({
    card: "",
    onqty: "",
    id_prodotto_interno: "",
    prezzo: "",
  });
  const toggleModal = (card, onqty, id_prodotto_interno, prezzo) => {
    setModal(!modal);
    seDatiModal({ card: card, onqty: onqty ,id_prodotto_interno: id_prodotto_interno, prezzo: prezzo });
  };
  const dispatch = useDispatch();
  let { id } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const isLogin = useSelector((state) => state.login.isLogin);
  const prodotti = useSelector((state) => state.ordini.offerte);
  const nuovo = useSelector((state) => state.login.attesanuovo);
  const loading = useSelector((state) => state.ordini.loading);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  const storico = useSelector((state) => state.ordini.storico);
console.log(storico)

  useEffect(() => {
    async function fetch_get_offerte() {
      await dispatch(get_offerte());
    }
    fetch_get_offerte();
  }, [id]);
 /*  useEffect(() => {
    async function fetch_storico() {
      await dispatch(get_storico_ordini(id_ordine));
    }
    fetch_storico();
  }, []); */
  useEffect(() => {
    dispatch(set_iscarrello(false));
  }, []);


  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const modalpass = (prodotto, qty, id_prodotto_interno, prezzo) => {
    return (
      <Modal
        toggleModal={toggleModal}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
      />
    );
  };

  const handleIncrement = (card, qty, id_prodotto_interno, prezzo) => {
    const p = {
      id_prodotto: card.id_prodotto,
      nome: card.nome,
      ingredienti_it: card.ingredienti_it,
      ingredienti_en: card.ingredienti_en,
      
      prezzo: prezzo,
      quantita: qty + 1,
      special: 0,
      newspecial: false,
      id_prodotto_interno: id_prodotto_interno,
    };

    dispatch(aggiungi_incrementa_prodotto_cart(p));
  };

  const handleDecrement = (card, qty, id_prodotto_interno, prezzo) => {
    const p = {
      id_prodotto: card.id_prodotto,
      nome: card.nome,
      ingredienti_it: card.ingredienti_it,
      ingredienti_en: card.ingredienti_en,
     
      prezzo: prezzo,
      special: 0,
      newspecial: false,
      id_prodotto_interno: id_prodotto_interno,
    };
    if (qty == 1) {
      dispatch(elimina_prodotto_cart(p));
    } else if (qty > 1) {
      p.quantita = qty - 1;
      dispatch(decrementa_prodotto_cart(p));
    }
  };
  let itemList = [];
  const map_card = () => {
    prodotti.forEach((prodotto, index) => {
      let qty = 0;
      let id_prodotto_interno = "1";
      carrello.every((elemento, index) => {
        if (prodotto.id_prodotto === elemento.id_prodotto) {
          qty = elemento.quantita;
          id_prodotto_interno = elemento.id_prodotto_interno;
          return false;
        }
        return true;
      });

      itemList.push(
        <Cards
          key={index}
          card={prodotto}
          onqty={qty}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          toggleModal={toggleModal}
          
        />
      );
    });
    itemList.push();
    return itemList;
  };
  return (
    <div className="prodotti">
      {modal ? modalpass() : null}
      {loading ? <Spinner /> : map_card()}
    </div>

  );
};

export default Offerte;
