import React from "react";
import validate from "../validators/validateInfo";
import useForm from "../validators/useForm";
import "../style/Form.css";
import { useSelector } from "react-redux";
import { setErrore } from "../store/actions/useform";
import { useDispatch } from "react-redux";

const FormLogin = ({ submitForm, login }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate,
    login
  );
  const dispatch = useDispatch();

  const errore = useSelector((state) => state.useform.error);
  const handleReset = async () => {
    errors.email = "";
    errors.password = "";
    await dispatch(setErrore());
  };
  return (
    <div className="page" noValidate>
      <div className="field field_v1">
        <input
          id="first-name"
          className="field__input"
          type="email"
          name="email"
          placeholder="Enter your email"
          value={values.email}
          onChange={handleChange}
          onFocus={handleReset}
        />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Email</span>
        </span>
        {errors.email && <p>{errors.email}</p>}
      </div>
      <div className="field field_v2">
        <input
          id="last-name"
          className="field__input"
          type="password"
          name="password"
          placeholder="Enter your password"
          value={values.password}
          onChange={handleChange}
          onFocus={handleReset}
        />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Password</span>
        </span>
        {errors.password && <p>{errors.password}</p>}
      </div>
      {errore && <p>{errore}</p>}
      {/* <button className="form-input-btn" type="submit">
        Login
      </button> */}
      <a href="#fakeLink" onClick={handleSubmit} className="btn purple" type="submit">
       Login
      </a>
    </div>
  );
};

export default FormLogin;
