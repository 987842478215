import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import BaseInfo from "../components/BaseInfo";
import FornoInfo from "../components/FornoInfo";
import FuoriInfo from "../components/FuoriInfo";
import Spinner from "../components/Spinner";
import { loading_login } from "../store/actions/login";
import {
  aggiungi_incrementa_prodotto_cart,
} from "../store/actions/ordini";
import "../style/Stepper.css";
import { setCarrello } from "../utils/session";
import uuid from "react-uuid";

const PizzaStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, uui } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const loading = useSelector((state) => state.ordini.loading);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const [page, setPage] = useState(0);
  const [error, setError] = useState("");

  let base = [];
  let basePizza = [];
  const preparadata = async() => {
   
    await ingredienti.forEach((element) => {
      if (
        element.categorie_ingredienti.nome == "Basi per Pizza Pesonalizzata"
      ) {
        basePizza.push(element);
        return base.push(false);
      }
    });
  };
  preparadata()
  const [formData, setFormData] = useState({
    nome: "",
    base: base,
    nota: "",
    descrizione_base: "",
    ingredienti_forno: [],
    ingredienti_fuori_forno: [],
    totale: 0.0,
  });
  const FormTitles = [
    "Scegli la base",
    "Scegli gli ingredienti in forno",
    "Scegli gli ingredienti dopo cottura",
  ];

  const handleIncrement = () => {
    dispatch(loading_login(true));

    let id_prodotto_interno = uuid();

    let descrizione =
      "La Pizza di " + formData.nome + ": " + formData.descrizione_base + ": ";
    descrizione = descrizione + ". - Prodotti in forno: ";
    formData.ingredienti_forno.forEach((item, index) => {
      descrizione = descrizione + " - " + item.nome;
    });
    descrizione = descrizione + ". - Prodotti dopo Cottura: ";
    formData.ingredienti_fuori_forno.forEach((item, index) => {
      descrizione = descrizione + " - " + item.nome;
    });
    descrizione = descrizione + ". - Nota: " + formData.nota;

    let prodotto = prodotti.find((pr) => pr.id_prodotto == id);
    if (!prodotto) {
      //TODO va gestita l'eccexione
      return;
    }
    const p = {
      id_prodotto: prodotto.id_prodotto,
      nome: "pizza Personalizzata",
      ingredienti_it: descrizione,
      ingredienti_en: "",
     
      prezzo: formData.totale,
      quantita: 1,
      special: 1,
      newspecial: true,
      id_prodotto_interno: id_prodotto_interno,
    };

    dispatch(aggiungi_incrementa_prodotto_cart(p));
    dispatch(loading_login(false));

    navigate("/carrello");
  };
  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
          <BaseInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
            basePizza={basePizza}
            ì
          />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <FornoInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
            basePizza={basePizza}
          />
        </>
      );
    } else {
      return (
        <>
          <FuoriInfo
            formData={formData}
            setFormData={setFormData}
            ingredienti={ingredienti}
            basePizza={basePizza}
          />
        </>
      );
    }
  };
  return (
    <div className="container-stepper">
      <div className="progressbar">
        <div
          style={{
            width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
          }}
        ></div>
      </div>
      <div className="titolo-page">{FormTitles[page]} </div>
      <div className="ingredienti_setter">
      {loading || formData.base.length == 0 ? (
          <Spinner />
        ) : (
          <div className="ingredienti">{PageDisplay()}</div>
        )}
        <div className="ingredienti">{error != "" ? error : null}</div>

        <div className="pizza-btn-container">
          {page > 0 ? (
            <a
              href="#fakeLink"
              onClick={() => {
                setPage((currPage) => currPage - 1);
              }}
              className="btn purple"
            >
              Indietro
            </a>
          ) : null}
          <a
            href="#fakeLink"
            onClick={() => {
              if (page === FormTitles.length - 1) {
                handleIncrement();
              } else {
                if (
                  formData.nome != "" /*  || */ &&
                  formData.descrizione_base != ""
                ) {
                  setPage((currPage) => currPage + 1);
                  setError("");
                } else {
                  setError("Il nome e una base sono obbligatori");
                }
              }
            }}
            className="btn purple"
          >
            {page === FormTitles.length - 1 ? "Conferma" : "Avanti"}
          </a>
        </div>
        <div className="totale-pizza">
          {" "}
          Per la tua Pizza stai spendendo: {Number(formData.totale).toFixed(
            2
          )}{" "}
          Euro
        </div>
      </div>
    </div>
  );
};

export default PizzaStepper;
