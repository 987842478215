import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "../style/Navbar.css";

const Navbar = () => {
  const categorie = useSelector((state) => state.login.categorie);
  const menu = useSelector((state) => state.login.menu);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  const storico = useSelector((state) => state.ordini.storico);
  console.log("Storico",storico)
  return (
    <nav className="nav">
      <div className="content-nav">
        {menu.map((voce, index) => (
          <NavLink
            to={`${voce.nome}/`}
            className="line"
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#7600dc" : null,
            })}
            key={index}
          >
            {voce.alias}
          </NavLink>
        ))}
        {id_ordine != -1 && storico!=0 ? (
          <NavLink
            to={`offerte/${id_ordine}/1`}
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#7600dc" : null,
            })}
          >
            Offerte
          </NavLink>
        ) : null}
        {id_ordine != -1 && storico!=0
          ? categorie.map((cat, index) => (
              <NavLink
                to={`products/${id_ordine}/${cat.id_categoria}/${cat.tipo}`}
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#7600dc" : null,
                })}
                key={index}
              >
                {cat.nome}
              </NavLink>
            ))
          : null}
       {/*  {id_ordine != 1 ? (
          <NavLink
            to="/logoutmassivo"
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#7600dc" : null,
            })}
          >
            Logout tutti i Tablet
          </NavLink>cp
        ) : null} */}
       {/*  <NavLink
          to="/qrmenu"
          style={({ isActive }) => ({
            color: isActive ? "#fff" : "#545e6f",
            background: isActive ? "#7600dc" : null,
          })}
        >
          QR Menu
        </NavLink> */}
        <NavLink
          to="/logout"
          style={({ isActive }) => ({
            color: isActive ? "#fff" : "#545e6f",
            background: isActive ? "#7600dc" : null,
          })}
        >
          Logout
        </NavLink>
      </div>
    </nav>
  );
};
export default Navbar;
