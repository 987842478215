import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {

  send_ordine_cameriere_cassa,
} from "../store/actions/ordini";
import Topbar from "./Topbar";

const PersistLogin = (props) => {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const totale = useSelector((state) => state.ordini.tot_prezzo);
  const storico = useSelector((state) => state.ordini.storico);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);

  const iscarrello = useSelector((state) => state.ordini.iscarrello);

  const handleSend = async () => {
    await dispatch(send_ordine_cameriere_cassa(carrello, totale, id_ordine));
  };
  return (
    <div className="container-prodotti">
      {id_ordine != -1 ? (
        <div className="top">
          <Topbar
            iscarrello={iscarrello}
            onSend={handleSend}
            storico={storico}
          />
        </div>
      ) : null}
      {props.id_ruolo == 1 ? <Navigate to="/" /> : <Outlet />}
    </div>
  );
};

export default PersistLogin;
